@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

*{
    font-family: 'Poppins', sans-serif;
    margin:0; padding:0;
    box-sizing: border-box;
    outline: none; border:none;
    text-decoration: none;
    text-transform: capitalize;
    transition: .2s linear;
}

.container1{
    background:linear-gradient(45deg, blueviolet, lightgreen);
    padding:15px 9%;
    padding-bottom: 100px;
}

.container1 .heading{
    text-align: center;
    padding-bottom: 20px;
    color:#fff;
    text-shadow: 0 5px 10px rgba(0,0,0,.2);
    font-size: 50px;
}

.container1 .box-container1{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap:15px;
}

.container1 .box-container1 .box{
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    border-radius: 5px;
    background: #fff;
    text-align: center;
    padding:30px 20px;
}

.container1 .box-container1 .box img{
    height: 80px;
}

.container1 .box-container1 .box h3{
    color:#444;
    font-size: 22px;
    padding:10px 0;
}

.container1 .box-container1 .box p{
    color:#777;
    font-size: 15px;
    line-height: 1.8;
}

.container1 .box-container1 .box .btn{
    margin-top: 10px;
    display: inline-block;
    background:#333;
    color:#fff;
    font-size: 17px;
    border-radius: 5px;
    padding: 8px 25px;
}

.container1 .box-container1 .box .btn:hover{
    letter-spacing: 2px;
}

.container1 .box-container1 .box:hover{
    box-shadow: 0 10px 15px rgba(0,0,0,.3);
    transform: scale(1.03);
}

@media (max-width:768px){
    .container1{
        padding:20px;
    }
}
.size {
    width: 210px;
    height: 300px;
}
