.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f0f0f0;
    padding: 50px;
  
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left;
      padding: 80px;
    }
  }
  